/* Color palette */
$red: #59A336; //OSMUS green
$red-light: #76C551; //OSMUS green light
$orange: #faa71e;
$blue-dark: #2c3038;
$blue-light: #929db3;
$blue-grey: #68707f;
$washed-blue: rgba(146, 157, 179, 0.1);
$grey-light: #e1e0e0;
$tan: #f0efef;
$white: #fff;
$sans-font-family: 'Source Sans Pro', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;