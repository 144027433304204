/* ==========================================================================
   Typography
   ========================================================================== */

.barlow-condensed {
  font-family: 'Zilla Slab', sans-serif;
  font-variant-numeric: lining-nums;
}

.base-font {
  font-family: $sans-font-family;
}

.f125 {
  font-size: 1.125rem;
}

.f8 {
  font-size: 0.625rem;
}

@media screen and (min-width: 30em) {
  .f125-ns {
    font-size: 1.125rem;
  }
}

@media screen and (min-width: 60em) and (max-width: 90em) {
  .f125-m {
    font-size: 1.125rem;
  }
}

@media screen and (min-width: 60em) {
  .f125-l {
    font-size: 1.125rem;
  }
}
